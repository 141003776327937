import { Row, Col } from 'antd';
import { Default, Aviva, Axa, Partnersand, Santam } from './contents';
import theme, { THEMES } from '../../services/theme';
import './footer.less';

const footerContents = {
  [THEMES.default]: <Default />,
  [THEMES.aviva]: <Aviva />,
  [THEMES.axa]: <Axa />,
  [THEMES.partnersand]: <Partnersand />,
  [THEMES.santam]: <Santam />,
};

export default () => (
  <footer className="site-footer">
    <div className="site-footer-container">
      {footerContents[theme.siteTheme] || <Default />}
      <Row>
        <Col span={24}>
          <p className="site-footer-copyright">
            &copy; 1999-{new Date().getFullYear()} LMI Group Pty Ltd | ABN 69
            086 256 171 | AFS Licence No. 283777
          </p>
        </Col>
      </Row>
    </div>
  </footer>
);
