import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

const Aviva = () => (
  <Row>
    <Col className="site-footer-nav-container" xs={14} md={18}>
      <ul className="site-footer-nav">
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/terms-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            TERMS &amp; CONDITIONS
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRIVACY &amp; SECURITY
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://www.aviva.co.uk/help-and-support/contact-us/complaints/"
            target="_blank"
            rel="noopener noreferrer"
          >
            COMPLAINTS
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="mailto:bicalcsupport@aviva.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            CONTACT US
          </a>
        </li>
      </ul>
    </Col>
    <Col xs={10} md={6}>
      <ul className="site-footer-social-links">
        <li className="site-footer-social-link-item">
          <a
            href="https://www.facebook.com/AvivaUK/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </li>
        <li className="site-footer-social-link-item">
          <a
            href="https://twitter.com/AvivaUK"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li className="site-footer-social-link-item">
          <a
            href="https://www.youtube.com/avivauk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </li>
      </ul>
    </Col>
  </Row>
);

export default Aviva;
